.swagger-auth-container {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.3);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 999;
}

.swagger-auth-content {
    background-color: #fff;
    padding: 5px 10px 20px 20px;
    border-radius: 8px;
    text-align: center;
    max-width: 50vw;
    width: 100%;
}

.swagger-auth-title {
    color: #333;
    font-size: 24px;
    margin-bottom: 20px;
}

.swagger-auth-input {
    width: 100%;
    padding: 12px;
    margin-bottom: 12px;
    border: 1px solid #ccc;
    border-radius: 5px;
    font-size: 16px;
    box-sizing: border-box;
}

.swagger-auth-button {
    background-color: #49cc90;
    color: white;
    border: none;
    padding: 12px 20px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 18px;
    border-radius: 5px;
    cursor: pointer;
}

.swagger-auth-error {
    color: red;
    margin-top: 12px;
}
